<template>
  <div class="event-list">
    <Action-panel :isReady="isPageReady">
      <Button-base
        :type="2"
        :text="translations.labels.button_add_liveSession"
        :hoverInfo="translations.labels.button_add_liveSession_hover"
        :onClick="newItem"
      />
    </Action-panel>

    <TableList
      :is-ready="isPageReady"
        :headers="headers"
        :items="list"
      />
  </div>
</template>

<script>
import Vue from "vue";
import Component from "vue-class-component";
import ActionPanel from "@/components/panel/ActionPanel";
import TablePanel from "@/components/panel/TablePanel";
import ButtonBase from "@/components/dom/ButtonBase";
import SvgIcon from "@/components/dom/SvgIcon";
import Round from "@/components/dom/Round";
import { toastService } from "@/services/toastService.js";
import TableList from "@/components/table/TableList";

@Component({
  components: {
    ActionPanel,
    TablePanel,
    ButtonBase,
    SvgIcon,
    Round,
    TableList
  },
  inject:{
    liveSessionService:"liveSessionService"
  }
})
export default class LiveSession_list extends Vue {
  isPageReady = false;
  model = [];
  search = "";

  async init() {
    try {
      this.model = await this.liveSessionService.list(this.queryParameters.idParent);
      this.isPageReady = true;
    } catch (error) {
      toastService.error(this.translations.errors.somethingWrong);
    }
  }

  get translations() {
    return this.$store.state.translationsStore.currentLang;
  }

  get list() {
    return this.model;
  }

  get queryParameters() {
    return this.$route.params;
  }

  get headers() {
    return [
      {
        text: this.translations.labels.liveSession_table_code,
        value: "code",
      },
      {
        text: this.translations.labels.liveSession_table_startDate,
        value: "startDate",
        type:"date"
      },
      {
        text: this.translations.labels.liveSession_table_duration,
        value: "duration",
        type:"custom",
        itemValue:(item)=>{return item.duration + "min";}
      },
      {
        text: this.translations.labels.liveSession_table_type,
        value: "type",
      },
      {
        text: this.translations.labels.liveSession_table_platform,
        value: "platform",
      },
      {
        text: this.translations.labels.liveSession_table_openingOption,
        value: "openingOption",
      },
      {
        text: this.translations.labels.table_detail,
        value: "detail",
        type: "link",
        formatValue: (item) => {
          return `/live-session/detail/${this.queryParameters.idParent}/${item.id}`;
        },
        icon: "mdi-feature-search-outline"
      },
      {
        text: this.translations.labels.table_edit,
        value: "edit",
        type: "link",
        formatValue: (item) => {
          return `/live-session/edit/${this.queryParameters.idParent}/${this.queryParameters.parentName}/${item.id}`;
        },
        icon: "mdi-pencil",
        buttonType: 2,
      },
      {
        text: this.translations.labels.table_enable,
        value: "enable",
        type: "switch",
        itemValue: (item) => {
          return item.isEnabled;
        },
        onAction: (item) => {
          this.swapEnabled(item.id, item.isEnabled);
        },
      },
      {
        text: this.translations.labels.liveSession_table_isAvailable,
        value: "isAvailable",
        type: "switch",
        itemValue: (item) => {
          return item.isAvailable;
        },
        onAction: (item) => {
          this.swapAvailability(item.id, item.isAvailable);
        },
      },
    ];
  }

  async swapEnabled(_id, _isEnabled) {
    this.isPageReady = false;
    try {
      if (!_isEnabled) await this.liveSessionService.enable(_id);

      if (_isEnabled) await this.liveSessionService.disable(_id);
    } catch (error) {
      toastService.error(this.translations.errors.somethingWrong);
    }

    this.model = await this.liveSessionService.list(this.queryParameters.idParent);
    this.toastSuccessEnabledLabel(_isEnabled);
    this.isPageReady = true;
  }

  async swapAvailability(_id, _isAvailable) {
    this.isPageReady = false;
    try {
      if (!_isAvailable) await this.liveSessionService.setAvailable(_id);

      if (_isAvailable) await this.liveSessionService.setNotAvailable(_id);

      this.model = await this.liveSessionService.list(this.queryParameters.idParent);
      this.toastSuccessAvailabilityLabel(_isAvailable);
    } catch (error) {
      toastService.error(this.translations.errors.somethingWrong);
    }

    this.isPageReady = true;
  }

  toastSuccessAvailabilityLabel(isEnabled) {
    isEnabled
      ? toastService.success(this.translations.success.liveSession_notavailable)
      : toastService.success(this.translations.success.liveSession_available);
  }
  toastSuccessEnabledLabel(isEnabled) {
    isEnabled
      ? toastService.success(this.translations.success.liveSession_disable)
      : toastService.success(this.translations.success.liveSession_enable);
  }

  newItem() {
    this.$router.push(
      `/live-session/new/${this.queryParameters.idParent}/${this.queryParameters.parentName}`
    );
  }
  created() {
    this.$store.commit(
      "SET_SECTION_TITLE",
      this.translations.pageTitles.liveSessions_list
    );
  }
  mounted() {
    this.init();
  }
}
</script>
<style lang="scss" scoped>
.event-list {
  .btn-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 20px 30px 0 30px;
    margin: 15px 30px 0 30px;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #2196f3;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}
</style>
